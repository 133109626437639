import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInVorarlberg = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Vorarlberg?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal ist
                    Vorarlberg an der Reihe.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – die derzeitige Situation</h2>
                <p>
                    Die derzeit günstigen Kreditzinsen auf dem Finanzmarkt treiben die Immobilienpreise hierzulande nach
                    oben: Seit dem vierten Quartal 2020 liegt der Preisanstieg konstant im zweistelligen Prozentbereich.
                    Im ersten Quartal 2021 betrug dieser Anstieg 12,3 %, im zweiten 11,7 %. Während sich der Anstieg der
                    Kaufpreise von Einfamilienhäusern außerhalb Wiens 2021 von 12,9 % im ersten auf 11,3 % im zweiten
                    Quartal verringerte, wurden gebrauchte Eigentumswohnungen in diesem Zeitfenster um 14,3 % teurer.
                    Beobachten lässt sich zudem, dass die Immobilienpreise außerhalb Wiens prinzipiell schneller
                    wachsen: Die Preise in der Hauptstadt stiegen im ersten Quartal 2021 um 10,9, im zweiten um 10,7 %.
                    In den anderen Bundesländern kam es in diesem Zeitraum zu Wachstumsraten von 14 bzw. 12,8 %.
                    Besonders Neubauwohnungsmarkt weist einen deutlichen Trend zum Preisanstieg: Stiegen die Preise für
                    neue Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es in den
                    übrigen Bundesländern zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Immobilienmarkt in Vorarlberg</h2>
                <p>
                    Ein deutlicher Preisanstieg ist in Vorarlberg nicht nur bei neuen Eigentumswohnungen zu beobachten.
                    Besonders gebrauchte Wohnungen und Einfamilienhäuser werden derzeit zunehmend teurer: Für ein
                    Einfamilienhaus in Feldkirch bezahlst du aktuell 2.936,40 € pro Quadratmeter, was einem Anstieg von
                    7 % gleichkommt. Teurer werden auch Baugrundstücke: Ein Quadratmeter kostet dich in Bludenz derzeit
                    noch 343,34 €; in Dornbirn und Bregenz sind es schon 542,24 bzw. 521,88 €. Rückläufig entwickeln
                    sich lediglich die Preise für Mietwohnungen. Das könnte darauf hindeuten, dass der Anstieg zumindest
                    bei diesen in näherer Zukunft ein Ende hat.
                </p>
                <hr />

                <h2>Das kostet ein Haus in Vorarlberg</h2>
                <p>
                    In Vorarlberg steigen die Preise von Häusern derzeit deutlich schneller als die von neuen
                    Eigentumswohnungen. In Bregenz liegt der Quadratmeterpreis bei 3.213,26 €. Feldkirch (2.936,40 €)
                    und Dornbirn (2.932,72 €) liefern sich ein regelrechtes Kopf-an-Kopf-Rennen. Bludenz liegt mit
                    2.766,52 € pro Quadratmeter ebenfalls nicht weit zurück.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInVorarlberg"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEinHausInVorarlberg"}
                heading={"Was kostet ein Haus in Vorarlberg?"}
            />
        </Layout>
    );
};

export default WasKostetEinHausInVorarlberg;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-vorarlberg", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
